var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"sm":"4"}},[_c('h4',{staticClass:"text-left"},[_vm._v(" Training completed + started for all training modules in the program ")]),_c('h5',{staticClass:"text-left text-muted"},[_vm._v(" License Start Date "+_vm._s(_vm.licenseStartDate)+" ")])])],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"sm":"12"}},[_c('validation-provider',{staticClass:"mr-1",attrs:{"vid":"time","rules":{ required: true },"name":"Date"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('label',{attrs:{"for":"datepicker-from"}},[_vm._v("From")]),_c('b-form-datepicker',{attrs:{"state":_vm.getValidationState(
            validationContext
          )},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
            validationContext
          )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}])}),_c('validation-provider',{attrs:{"vid":"time","rules":{ required: true },"name":"Date"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('label',{attrs:{"for":"datepicker-to"}},[_vm._v("To")]),_c('b-form-datepicker',{attrs:{"state":_vm.getValidationState(
            validationContext
          )},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
            validationContext
          )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}])})],1)],1),(_vm.isLoading)?_c('aom-skeleton-loader'):_c('section',{staticClass:"mt-2"},[_c('vue-good-table',{staticClass:"mt-3",attrs:{"mode":"remote","is-loading":_vm.isTableLoading,"columns":_vm.columns,"rows":_vm.rows,"rtl":_vm.isRtl,"search-options":{
          enabled: false,
        },"pagination-options":{
          enabled: true,
          perPage: _vm.perPage,
        },"style-class":"vgt-table striped"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('div',{staticClass:"justify-content-between flex-wrap vgt-center-align",attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"align-items-center mb-0 mt-0"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.righttop",value:('View Participant'),expression:"'View Participant'",modifiers:{"hover":true,"righttop":true}}],staticClass:"btn-icon",attrs:{"variant":"outline-none","to":{
                name: 'champion-program-participant',
                params: { id: _vm.defaultProgramId, participantId: props.row.user_id },
              }}},[_c('feather-icon',{attrs:{"icon":"EyeIcon","size":"20"}})],1)],1)]):(props.column.field === 'full_name')?_c('div',{staticClass:"justify-content-between flex-wrap",attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"align-items-center mb-0 mt-0"},[_c('router-link',{attrs:{"to":{
                name: 'champion-program-participant',
                params: { id: _vm.defaultProgramId, participantId: props.row.id },
              }}},[_vm._v(" "+_vm._s(props.row.full_name)+" ")])],1)]):(props.column.field === 'status_id')?_c('span',[_c('b-badge',{attrs:{"variant":_vm.STATUS_COLOR[_vm.getStatus(props.row)]}},[_vm._v(" "+_vm._s(_vm.getStatus(props.row))+" ")])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('table-pagination',{attrs:{"per-page":_vm.perPage,"total":_vm.total},on:{"perPageChanged":function (value) { return props.perPageChanged({ currentPerPage: value }); },"pageChanged":function (value) { return props.pageChanged({ currentPage: value }); }}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }