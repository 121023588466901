<template>
  <section>
    <b-card>
      <b-row>
        <b-col sm="4">
          <h4 class="text-left">
            Training completed + started for all training modules in the program
          </h4>
          <h5 class="text-left text-muted">
            License Start Date {{ licenseStartDate }}
          </h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" class="d-flex justify-content-end">
          <validation-provider v-slot="validationContext" vid="time" :rules="{ required: true }" name="Date"
            class="mr-1">
            <label for="datepicker-from">From</label>
            <b-form-datepicker v-model="fromDate" :state="getValidationState(
              validationContext
            )
              " />
            <b-form-invalid-feedback :state="getValidationState(
              validationContext
            )
              ">
              {{
                validationContext.errors[0]
              }}
            </b-form-invalid-feedback>
          </validation-provider>
          <validation-provider v-slot="validationContext" vid="time" :rules="{ required: true }" name="Date">
            <label for="datepicker-to">To</label>
            <b-form-datepicker v-model="toDate" :state="getValidationState(
              validationContext
            )
              " />
            <b-form-invalid-feedback :state="getValidationState(
              validationContext
            )
              ">
              {{
                validationContext.errors[0]
              }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-col>
      </b-row>
      <aom-skeleton-loader v-if="isLoading" />
      <section v-else class="mt-2">
        <vue-good-table class="mt-3" mode="remote" :is-loading="isTableLoading" :columns="columns" :rows="rows"
          :rtl="isRtl" :search-options="{
            enabled: false,
          }" :pagination-options="{
            enabled: true,
            perPage: perPage,
          }" style-class="vgt-table striped" @on-page-change="onPageChange" @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange">
          <template slot="table-row" slot-scope="props">
            <!-- Column: Action -->
            <div v-if="props.column.field === 'action'" cols="12" md="2"
              class="justify-content-between flex-wrap vgt-center-align">
              <div class="align-items-center mb-0 mt-0">
                <b-button v-b-tooltip.hover.righttop="'View Participant'" variant="outline-none" class="btn-icon" :to="{
                  name: 'champion-program-participant',
                  params: { id: defaultProgramId, participantId: props.row.user_id },
                }">
                  <feather-icon icon="EyeIcon" size="20" />
                </b-button>

              </div>
            </div>
            <div v-else-if="props.column.field === 'full_name'" cols="12" md="2"
              class="justify-content-between flex-wrap">
              <div class="align-items-center mb-0 mt-0">
                <router-link :to="{
                  name: 'champion-program-participant',
                  params: { id: defaultProgramId, participantId: props.row.id },
                }">
                  {{ props.row.full_name }}
                </router-link>
              </div>
            </div>

            <span v-else-if="props.column.field === 'status_id'">
              <b-badge :variant="STATUS_COLOR[getStatus(props.row)]">
                {{ getStatus(props.row) }}
              </b-badge>
            </span>
            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <table-pagination :per-page="perPage" :total="total" @perPageChanged="(value) => props.perPageChanged({ currentPerPage: value })
              " @pageChanged="(value) => props.pageChanged({ currentPage: value })
                " />
          </template>
        </vue-good-table>
      </section>
    </b-card>
  </section>
</template>

<script>
import {
  BButton,
  BCard,
  BFormDatepicker,
  BCol,
  BBadge,
  BRow,
  BFormInvalidFeedback,
  BFormGroup,
  VBTooltip,
  BDropdown,
  BDropdownItem
} from "bootstrap-vue";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import { trainingStatus, trainingStartedOrCompletedStatusDisplay } from '@models';
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import { reportService, groupsService } from "@/services";
import { mapGetters } from 'vuex';
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
import { STATUS_COLOR, makeErrorToast } from "@/libs/utils";
import { ValidationProvider } from "vee-validate";
// eslint-disable-next-line
import vSelect from "vue-select";
import useAomTableConfig from "@aom-core/useAomTableConfig.js";

import {
  getValidationState,
  localeDateStringFromIsoDateTime
} from "@/libs/utils";

import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";

export default {
  name: 'MonthlyLoginReport',
  components: {
    BButton,
    BBadge,
    BCard,
    VueGoodTable,
    TablePagination,
    AomSkeletonLoader,
    BFormDatepicker,
    ValidationProvider,
    BFormInvalidFeedback,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BDropdown,
    BDropdownItem
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      isLoading: false,
      fromDate: new Date(),
      toDate: new Date(),
      modalShow: false,
      columns: [
        {
          label: "Name",
          field: "full_name",
          filterOptions: {
            enabled: true,
            placeholder: "Name",
          },
          width: "12em",
        },
        {
          label: "Module",
          field: "course_name",
          filterOptions: {
            enabled: true,
            placeholder: "Module",
          },
          width: "10em",
        },
        {
          label: "Status",
          field: "status_id",
          filterable: true,
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: Object.values(trainingStartedOrCompletedStatusDisplay),
          },
          width: "6em",
        },
        {
          field: 'action',
          sortable: false,
          width: "10em"
        }
      ],
      rows: [],
      columnFilters: [],
      sort: [],
      isTableLoading: false
    };
  },
  computed: {
    ...mapGetters('programs', ['defaultProgramId', 'defaultProgram']),
    isRtl() {
      return store.state.appConfig.isRTL;
    },
    licenseStartDate() {
      return localeDateStringFromIsoDateTime(this.defaultProgram?.licence_start_date);
    }
  },
  watch: {
    defaultProgram: {
      handler(n) {
        if (n) {
          this.loadPageData();
        }
      },
      deep: true,
      immediate: true
    },
    fromDate(n) {
      if(n) {
        this.loadItems();
      }
    },
    toDate(n) {
      if(n) {
        this.loadItems();
      }
    }
  },
  methods: {
    getStatus(row) {
      if (row.status_id === null) {
        return trainingStartedOrCompletedStatusDisplay[trainingStatus.NOT_STARTED];
      }
      return trainingStartedOrCompletedStatusDisplay[row.status_id];
    },
    async loadPageData() {
      this.fromDate = this.defaultProgram?.licence_start_date;
      try {
        this.isLoading = true;
        await Promise.all([
          this.loadItems(),
          this.loadProgramGroup()
        ]);
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },

    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },

    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of [
        "full_name",
        "course_name",
        "status_id"
      ]) {
        if (params.columnFilters[col]) {
          if (col === "course_name") {
            columnFilters.push({
              field: col,
              value: params.columnFilters[col]
            });
          } else if (col === 'full_name') {
            columnFilters.push({
              field: 'full_name',
              value: params.columnFilters[col]
            });
          } else if (col === 'status_id') {
            columnFilters.push({
              field: 'status',
              value: params.columnFilters[col]
            });
          }
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },
    async loadItems() {
      try {
        this.isTableLoading = true;
        const programId = this.$route.params.id;
        const response = await reportService.getLearningsList(programId, {
          page: this.page,
          perPage: this.perPage,
          columnFilters: this.columnFilters,
          sort: this.sort
        },
          this.toDate,
          this.fromDate
        );
        const { items, total } = response.data;
        this.total = total;

        this.rows = items.map(item => ({
          ...item,
          full_name: `${item.first_name} ${item.last_name}`,
        }));
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast(e));
      } finally {
        this.isTableLoading = false;
      }

    },
    async loadProgramGroup() {
      try {
        this.isLoading = true;
        const programId = this.$route.params.id;
        const response = await groupsService.listGroups(programId);
        const { data } = response;
        const { items } = data;
        this.programGroups = items;
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast(e));
      } finally {
        this.isLoading = false;
      }
    }
  },
  setup() {
    const { total, perPage, page } = useAomTableConfig();
    return {
      STATUS_COLOR,
      localeDateStringFromIsoDateTime,
      getValidationState,
      total, perPage, page
    };
  }
};
</script>

<style lang="scss">
@import '@/assets/scss/vue/libs/vue-good-table.scss';
</style>
